<script setup>
import {onMounted} from "vue"

import {useGameStore} from "@shared/stores/GameStore"

const authStore = useAuthStore()
const gameStore = useGameStore()

import GameList from "@shared/components/molecules/GameList.vue"
import {useAuthStore} from "@shared/stores/AuthStore"

onMounted(function () {
    gameStore.fetchGames(false, {isSilent: true})
    toggleButtonText("SIGN UP NOW", "IT ONLY TAKES 30 SECONDS!")
})

function toggleButtonText(text1, text2) {
    const button = document.querySelector('#btnJoinNow')
    const className1 = 'btnJoinNow__text--1'
    const className2 = 'btnJoinNow__text--2'
    if (!button) return

    function cycleText() {
        button.innerText = text1
        button.classList.remove(className2)
        button.classList.add(className1)
        setTimeout(() => {
            button.innerText = text2
            button.classList.remove(className1)
            button.classList.add(className2)
            setTimeout(cycleText, 2000)
        }, 1000)
    }

    cycleText()
}
</script>

<template>
    <div id="homePage">
        <div id="divImageSlider">
            <div class="imageSliderComponent">
                <div class="divImageScroller" style="left: 0%; width: 100%;">
                    <div class="divSliderItem" style="width: 100%;"></div>
                </div>
                <div class="btnPrevious"></div>
                <div class="btnNext"></div>
            </div>

            <div class="imageSliderComponent">
                <div class="divImageScroller" style="left: 0%; width: 100%;">
                    <div class="divSliderItem" style="width: 100%;">
                        <picture>
                            <template v-for="size in [320,640,768,1024,1366,1600]" :key="size">
                                <source :media="`(max-width: ${size}px)`" :srcset="`/images/pages/sliderImages/slide1@${size}.webp`" type="image/webp">
                                <source :media="`(max-width: ${size}px)`" :srcset="`/images/pages/sliderImages/slide1@${size}.jpg`" type="image/jpg">
                            </template>

                            <img class="imgSliderImage"
                                 src="/images/pages/sliderImages/slide1.jpg"
                                 alt="SOUTH AFRICA'S LARGEST ONLINE CASINO"
                                 style="aspect-ratio: 96/31"
                            >
                        </picture>
                        <div class="sliderCaptions">
                            <span class="sliderCaption sliderCaption--1">SA'S LARGEST ONLINE CASINO</span>
                            <span class="sliderCaption sliderCaption--2">250% BONUS + 100 FREE SPINS</span>
                            <span class="sliderCaption sliderCaption--3">FASTEST PAYOUTS!</span>
                        </div>
                    </div>
                </div>
                <div class="btnPrevious"></div>
                <div class="btnNext"></div>
            </div>
        </div>

        <div class="centerBtn">
            <button
                @click="authStore.setAuthModalVisibility(true, 'registration')"
                id="btnJoinNow"
            >
                SIGN UP NOW
            </button>
        </div>

        <div id="populargames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">MOST POPULAR</h2>

            </div>
            <div id="form"></div>
            <div class="wrapper">
                <GameList
                    group="mostPopular"
                    :url="{name: 'webplay.most-popular'}"
                    :display-amounts="{xl: 15, md: 12, sm: 6}"
                />
            </div>
        </div>

        <div id="livecasinogames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">LIVE CASINO</h2>
            </div>
            <div class="wrapper">
                <GameList
                    group="Lives"
                    :url="{name: 'webplay.live-casino'}"
                />
            </div>
        </div>

        <div id="topgames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">TOP RATED</h2>
            </div>
            <div class="wrapper">
                <GameList
                    group="topRated"
                    :url="{name: 'webplay.top-games'}"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#homePage .centerBtn #btnJoinNow {
    &.btnJoinNow__text--2 {
        font-size: 35px;

        @media (max-width: 350px) {
            font-size: 25px;
        }
    }
}

.sliderCaptions {
    position: absolute;
    left: 50%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    transform: translateX(-50%);

    .sliderCaption {
        font-size: 40px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        line-height: 0.9;

        &--1 {
            font-size: 40px;
        }

        &--2 {
            font-size: 65px;
        }

        &--3 {
            font-size: 40px;
        }
    }

    @media (max-width: 800px) {
        .sliderCaption {
            &--1 {
                font-size: 30px;
            }

            &--2 {
                font-size: 45px;
            }

            &--3 {
                font-size: 30px;
            }
        }
    }

    @media (max-width: 500px) {
        .sliderCaption {
            &--1 {
                font-size: 20px;
            }

            &--2 {
                font-size: 30px;
            }

            &--3 {
                font-size: 20px;
            }
        }
    }
}
</style>
