import {useApi} from "@shared/composables/useApi"
import {useAccountStore} from "@shared/stores/AccountStore"
import {useAuthStore} from "@shared/stores/AuthStore"
import {defineStore} from "pinia"
import {computed, ref} from "vue"

const GATEWAY_CALLPAY = 'callpay'
const GATEWAY_BONUS = 'bonus'

const DEPOSIT_TYPE_EFT = "eft"
const DEPOSIT_TYPE_CREDIT_CARD = "credit_card"
const DEPOSIT_TYPE_CRYPTO = "crypto"

export const useWalletStore = defineStore('WalletStore', () => {
    const { apiGet, apiPost } = useApi()

    const accountStore = useAccountStore()
    const authStore = useAuthStore()

    const isPayment3Enabled = ref(false)
    const isPayment3AbsaEnabled = ref(false)
    const isWalletFetched = ref(false)
    const isWalletModalVisible = ref(false)

    const canWithdraw = ref(false)
    const depositBonusPercentage = ref(0)
    const depositWagerPercentage = ref(0)
    const wallets = ref([])

    const getBalance = (gateway) => parseFloat(wallets.value.find(wallet => wallet.gateway === gateway)?.balance || '0.00')
    const getMoneyBalance = computed(() => getBalance(GATEWAY_CALLPAY))
    const getBonusBalance = computed(() => getBalance(GATEWAY_BONUS))
    const getCombinedBalance = computed(() => getMoneyBalance.value + getBonusBalance.value)

    const getDepositPaymentType = (paymentOption) => {
        const paymentTypes = {
            'payment3': DEPOSIT_TYPE_EFT,
            'voucherUp': DEPOSIT_TYPE_EFT,
            'capitec': DEPOSIT_TYPE_EFT,
            'discoveryBank': DEPOSIT_TYPE_EFT,
            'eft': DEPOSIT_TYPE_EFT,
            'creditCard': DEPOSIT_TYPE_CREDIT_CARD,
            'bitcoin': DEPOSIT_TYPE_CRYPTO
        }
        return paymentTypes[paymentOption] || ''
    }

    const getDepositPaymentGateway = (paymentOption) => {
        const paymentGateways = {
            'payment3': 'payment3',
            'voucherUp': 'voucherup',
            'capitec': 'paypump',
            'discoveryBank': 'paypump',
            'eft': 'callpay',
            'creditCard': 'callpay',
            'bitcoin': 'callpay'
        }
        return paymentGateways[paymentOption] || ''
    }

    const postTransaction = async (endpoint, payload) => {
        const { data } = await apiPost(endpoint, payload)
        fetchWallet()
        return data
    }

    const postDeposit = async (payload) => {
        return postTransaction('/api/deposit', payload)
    }

    const postWithdrawal = async (payload) => {
        return postTransaction('/api/withdraw', payload)
    }

    const setWalletModalVisibility = async (isVisible) => {
        if(!accountStore.isAuthenticated) {
            authStore.setAuthModalVisibility(true, 'login')
            return
        }

        if (isVisible) {
            await fetchWallet()
        }
        isWalletModalVisible.value = isVisible
    }

    const fetchWallet = async (options = {}) => {
        try {
            const { data } = await apiPost('/api/wallet', {}, options)
            canWithdraw.value = data.can_withdraw
            depositBonusPercentage.value = data.depositBonusPercentage
            depositWagerPercentage.value = data.depositWagerPercentage
            wallets.value = data.wallets
            isPayment3Enabled.value = data.is_payment3_enabled
            isPayment3AbsaEnabled.value = data.is_payment3_absa_enabled
            isWalletFetched.value = true
        } catch (e) {
            //
        }
    }

    const validateDeposit = async ({provider, paymentId}) => {
        const { data } = await apiGet(
            `/api/wallet/depositStatus/${provider}/${paymentId}`,
            { isSilent: true }
        )
        return data
    }

    return {
        isPayment3Enabled,
        isPayment3AbsaEnabled,
        isWalletModalVisible,
        isWalletFetched,
        canWithdraw,
        depositBonusPercentage,
        depositWagerPercentage,
        getBonusBalance,
        getCombinedBalance,
        getMoneyBalance,
        getDepositPaymentGateway,
        getDepositPaymentType,
        fetchWallet,
        postDeposit,
        postWithdrawal,
        setWalletModalVisibility,
        validateDeposit
    }
})
