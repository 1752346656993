<script setup>
import {useLoaderStore} from "@shared/stores/LoaderStore"

const loaderStore = useLoaderStore()
</script>

<template>

    <div v-if="loaderStore.loaderCount" id="divAppLoader" tabindex="0" class="show">
        <svg viewBox="0 0 32 32" width="32" height="32">
            <circle class="svgSpinner" cx="16" cy="16" r="14" fill="none"></circle>
        </svg>
    </div>

</template>
