<script setup>
import { onClickOutside } from '@vueuse/core'
import {reactive, ref} from "vue"

import {useAccountStore} from "@shared/stores/AccountStore"
import {useCouponStore} from "@shared/stores/CouponStore"
import {useLoyaltyChestStore} from "@shared/stores/LoyaltyChestStore"
import {useWageringStore} from "@shared/stores/WageringStore"

const accountStore = useAccountStore()
const couponStore = useCouponStore()
const loyaltyChestStore = useLoyaltyChestStore()
const wageringStore = useWageringStore()

const state = reactive({
    isOpen: false,
    isAuthenticated: true
})

const target = ref(null)

onClickOutside(target, () => {
    if(state.isOpen) {
        state.isOpen = false
    }
})

const handleVisitLink = (url) => {
    window.location.href = url
}

</script>

<template>

    <div
        id="divHeaderAccount"
        ref="target"
        @click="state.isOpen = !state.isOpen"
        :class="{
            'focus': state.isOpen,
            'show': accountStore.isAuthenticated,
        }"
    >
        <div class="divUserAccountIcon fa fa-user-circle">
            <div class="divUserAccountPresent"></div>
        </div>
        <div class="divUserAccountDropDown">
            <div class="divAccountDetails">
                <div class="divAccountIcon fa fa-user-circle"></div>
                <div id="divAccountUser">
                    {{ accountStore.user?.name }}
                    <br/>
                    <span>{{ accountStore.user?.email }}</span>
                </div>
            </div>
            <div id="divUserAccountMenu">
                <div
                    class="divAccountMenuItem"
                    @click="accountStore.openProfileModal"
                >
                    PROFILE
                </div>
                <div
                    class="divAccountMenuItem"
                    @click="() => couponStore.setCouponModalVisibility(true)"
                >
                    REDEEM COUPON
                </div>
                <div
                    class="divAccountMenuItem"
                    @click="() => wageringStore.setWageringModalVisibility(true)"
                >
                    BONUS WAGERING
                </div>
                <div
                    v-if="appHasLoyalty"
                    class="divAccountMenuItem"
                    @click="() => loyaltyChestStore.setLoyaltyChestModalVisible(true)"
                >
                    LOYALTY CHESTS
                </div>
                <div
                    class="divAccountMenuItem"
                    @click="() => handleVisitLink('/tournaments')"
                >
                    TOURNAMENTS
                </div>
                <div
                    v-if="useWelcomePackage && !accountStore.user.welcome_package_expired"
                    class="divAccountMenuItem"
                    @click="() => handleVisitLink('/welcomePage')"
                >
                    WELCOME PACKAGE
                </div>
                <div class="divAccountMenuItem" @click="accountStore.signOut">
                    SIGN OUT
                </div>
            </div>
        </div>
    </div>
</template>
