<script setup>
import {reactive} from "vue";

const state = reactive({
    isFooterExpanded: false
})
</script>

<template>
    <footer>
        <div class="footerwrapper">
            <div class="top">
                <div class="logo">
                    <img src="/images/jackpot-logo.svg" alt="Jackpot Logo" />
                </div>
                <div class="links">
                    <nav>
                        <h4>Casino</h4>
                        <router-link :to="{name: 'webplay.live-casino'}">Live Casino</router-link>
                        <router-link :to="{name: 'promotions'}">Promotions</router-link>
                    </nav>
                    <nav>
                        <h4>Company</h4>
                        <router-link :to="{name: 'how-to'}">How To</router-link>
                        <router-link :to="{name: 'about-us'}">About Us</router-link>
                        <a href="mailto:support@jackpot.co.za">Contact Us</a>
                    </nav>
                    <nav>
                        <h4>Resources</h4>
                        <router-link :to="{ name: 'terms-and-conditions' }">Terms</router-link>
                        <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
                        <router-link :to="{ name: 'responsible-gambling' }">Responsible Gaming</router-link>
                    </nav>
                </div>
            </div>
            <div class='top-seals'>
                <img src="/images/18.png" alt="18+" />
                <div class="dmca">
                    <div class="dmca--left">DMCA</div>
                    <div class="dmca--right">Protected</div>
                </div>
                <img src="/images/service.png" alt="Service" />
            </div>
            <div class="middle">
                <div class="content" :class="{ 'show': state.isFooterExpanded }" :style="{ 'height': state.isFooterExpanded ? '281px' : '150px' }">
                    <h4>WE ARE THE PLACE TO BE</h4>
                    <p>
                        Our focus is on bringing you the latest and greatest slots games – especially those big, juicy progressive jackpot games – as well as a full offering of table games and live dealer games. Add in amazing bonuses, sweet promotions, the latest news and a team who are always here for you and you will soon see why we are called {{ appName }}.
                    </p>
                    <p>
                        South African online casino players are spoilt for choice when it comes to just how many online casinos we can choose from. But the one thing that we’ve been missing is a playing partner who is truly in it for the joy of playing – until now.
                    </p>
                    <button id="btnJoinNowFooter" @click="authStore.setAuthModalVisibility(true, 'registration')">JOIN NOW</button>
                </div>
                <span class="mask"></span>
                <button class="toggler" @click="state.isFooterExpanded = !state.isFooterExpanded">
                    <span></span><span></span><span></span>
                </button>
            </div>
            <div class="bottom">
                <div class="licenses">
                    <a href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbTVoZUhwd01VTkRXWGRRSzFGd05DdEhjbnBsVUhjOVBTSXNJblpoYkhWbElqb2lVVzlsUldsSVkwZGFkRE51UzNvMEwyTjFlV0ZRVVQwOUlpd2liV0ZqSWpvaU56a3laakptT1RVNE0yVTFZbUU1WkdObFlURTFOMlU1TldSaU5UWXpaRE16TnpJeU9EUmpaRFEyWVRNd00yVTBZbVV5TkRFM1pHRmhNMkZoTlRBd05TSXNJblJoWnlJNklpSjk=" target="_blank">
                        <img src="/images/licenses/GCB-license.svg" alt="GCB License"/>
                    </a>
                </div>
            </div>
            <div class="bottom">
                <div class="over18">+18</div>
                <div class="copyright">© 2025 Jackpot All rights reserved. www.jackpot.co.za is operated by Gleneagles B.V., Curacao company registration number 158256 at, Johan Van WalbeeckPlein 24, Curacao</div>
            </div>
        </div>
        <div class="banking">
            <img src="https://assets.jackpot.co.za/banking/dep-zar-white.svg" class="depositmethods" alt="Deposit methods"/>
        </div>
    </footer>
</template>

<style scoped>
    .homeRouterLink {
        color: #007a4d;
        text-decoration: none;
    }

    .licenses {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        img {
            width: 100px;
            height: auto;
        }
    }
</style>
